import React from 'react';
import {
  Box,
  Chip,
  Button,
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../services/utils.service';
import notFoundImg from '../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import moment, { Moment } from 'moment';
import { Api } from '../services/api.service';
import { Vendor } from '../services/types/vendor.type';
import AddIcon from '@material-ui/icons/Add';
import { Product } from '../services/types/product.type';
import { ProductModal } from './productModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);

let timeout: any;


const Row = (props: { product: Product, onProductSelected: (product: Product) => void }) => {
  const { product } = props;
  const { t } = useTranslation(['products']);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size='small' onClick={() => props.onProductSelected(product)}>
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {product.id}
        </TableCell>
        <TableCell>
          {product.name}
        </TableCell>
        <TableCell>
          {product.tags.join(', ')}
        </TableCell>
        <TableCell>
          {`${product.tax} %`}
        </TableCell>
        <TableCell>
          {utils.currencyFormat(product.price)}
        </TableCell>
        <TableCell>
          <Chip label={t(product.enabled ? 'YES' : 'NO')} color={product.enabled?'primary':'default'}/>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export const Products = () => {
  const { t } = useTranslation(['products']);
  const api = new Api();
  const [loading, setLoading] = React.useState(true);
  const [products, setProducts] = React.useState<Product[]>([]);

  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(null);
  const [addingProduct, setAddingProduct] = React.useState(false);

  const [vendors, setVendors] = React.useState<Vendor[]>([]);
  const [selectedVendor, selectVendor] = React.useState<any | null>(null);

  const classes = useStyles();


  const loadData = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const products = await api.listProducts(selectedVendor.id);
      setProducts(products);
    }, () => {
      setLoading(false);
    })
  }



  React.useEffect(() => {
    utils.runAsync(async () => {
      const adminData = await api.getAdminData(localStorage.getItem('token')!);
      const vendors = await api.getVendors(adminData.venue_id);
      const filteredVendors = vendors.filter(v => adminData.vendor_ids.includes(v.id));
      setVendors(filteredVendors);
      selectVendor(filteredVendors[0]);
    });
  }, []);


  React.useEffect(() => {
    if (selectedVendor === null) return;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      loadData();
    }, 100);
  }, [
    selectedVendor
  ]);

  const onProductSelected = (product: Product) => {
    setSelectedProduct(product);
  }


  return (
    <>
      <ProductModal
        open={selectedProduct !== null || addingProduct}
        product={selectedProduct}
        vendor={selectedVendor}
        onClose={() => {
          setSelectedProduct(null);
          setAddingProduct(false);
          loadData();
        }}
      />
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item container spacing={2} alignItems='flex-end'>
            <Grid container item xs={12} justifyContent='flex-end'>
              <Button disabled={loading} variant='outlined' startIcon={<AddIcon />} onClick={()=>{setAddingProduct(true)}}>
                {t('ADD')}
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                onChange={(event, value) => {
                  selectVendor(value);
                }}
                value={selectedVendor}
                options={vendors}
                disableClearable={true}
                autoHighlight
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('SEARCH_VENDOR')}
                    variant='standard'
                    fullWidth={true}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>#</TableCell>
                        <TableCell>{t('NAME')}</TableCell>
                        <TableCell>{t('TAGS')}</TableCell>
                        <TableCell>{t('TAX')}</TableCell>
                        <TableCell>{t('PRICE')}</TableCell>
                        <TableCell>{t('ENABLED')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {products.map(p =>
                        <Row
                          onProductSelected={onProductSelected}
                          product={p}
                          key={p.id}
                        />)}
                      {loading && products.length === 0 &&
                        <TableCell colSpan={7}>
                          <Box padding={2}>
                            <Grid container justifyContent='center' item xs={12}>
                              <CircularProgress />
                            </Grid>
                          </Box>
                        </TableCell>
                      }
                      {!loading && products.length === 0 &&
                        <TableCell colSpan={7}>
                          <Box padding={2}>
                            <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                            <img className={classes.img} src={notFoundImg} />
                          </Box>
                        </TableCell>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid >
    </>
  )
}
