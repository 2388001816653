import { hot } from 'react-hot-loader';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Router } from './router';
import { DatePickerProvider } from './components/datePicketProvider';



let theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#674aee',
      main: '#231EBA',
      dark: '#000089',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#2a2d5a',
      main: '#000330',
      dark: '#00000a',
      contrastText: '#ffffff',
    },
  },
});
theme = responsiveFontSizes(theme);

const App = () => (
  <React.Fragment>
    <DatePickerProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <Router />
        </ThemeProvider>
    </DatePickerProvider >
  </React.Fragment>
)

export default hot(module)(App);
