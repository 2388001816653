import { Button, ButtonGroup, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import moment from 'moment';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { AlertDialog } from '../components/alertDialog';
import { ConfirmationDialog } from '../components/confirmationDialog';
import { Config } from '../config';
import { Api } from '../services/api.service';
import { DecoratedOrder } from '../services/types/decoratedOrder.type';
import { OrderStatus } from '../services/types/orderStatus.type';
import utils from '../services/utils.service';
import PrintIcon from '@material-ui/icons/Receipt';
import FastForwardIcon from '@material-ui/icons/FastForward';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

type Props = {
  orderId: number | null;
  open: boolean;
  onClose: () => void;
}
export const OrderDetailsModal = (props: Props) => {

  const { t } = useTranslation('orders');

  const api = new Api();
  const config = new Config();

  const [loading, setLoading] = React.useState(false);
  const [orderData, setOrderData] = React.useState<DecoratedOrder | null>(null);
  const [showRefundModal, setShowRefundModal] = React.useState(false);
  const [showErrorDialog, setShowErrorDialog] = React.useState(false);
  
  React.useEffect(() => {
    setOrderData(null);
    if (props.orderId) {
      setLoading(true);
      loadOrder();
    }
  }, [props.orderId]);

  const loadOrder = () => {
    utils.runAsync(async () => {
      setOrderData(await api.getOrder(localStorage.getItem('token')!, props.orderId!));
    }, (e) => {
      setLoading(false);
      if (e) {
        props.onClose();
      }
    });
  }

  const getChipColorForOrderStatus = (status: OrderStatus) => {
    if (status === OrderStatus.CREATED) {
      return 'default';
    } else if (status === OrderStatus.PROCESSING) {
      return 'secondary';
    }
    return 'primary';
  }

  const getUpdateStatusButtonLabel = () => {
    if (orderData?.order.status === OrderStatus.CREATED) {
      return 'MARK_PRINTED';
    } else if (orderData?.order.status === OrderStatus.PROCESSING) {
      return 'MARK_DELIVERED'
    } else {
      return 'MARK_CREATED';
    }
  }
  const getNewStatus = () => {
    if (orderData?.order.status === OrderStatus.CREATED) {
      return OrderStatus.PROCESSING;
    } else if (orderData?.order.status === OrderStatus.PROCESSING) {
      return OrderStatus.DELIVERING;
    } else {
      return OrderStatus.CREATED;
    }
  }

  const updateStatus = () => {
    utils.runAsync(async () => {
      setLoading(true);
      await api.updateOrderStatus(
        props.orderId!,
        getNewStatus(),
        localStorage.getItem('token')!
      );
    }, (e) => {
      loadOrder();
      if (e) {
        setShowErrorDialog(true);
      }
    });
  }

  const refund = () => {
    setShowRefundModal(false);
    utils.runAsync(async () => {
      setLoading(true);
      await api.refundPayment(
        orderData!.payment.id,
        localStorage.getItem('token')!
      );
    }, (e) => {
      loadOrder();
      if (e) {
        setShowErrorDialog(true);
      }
    });
  }

 

  const openTicket = (reference: string) => {
    const width = 600;
    const height = 600;
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    window.open(
      `${config.RECEIPT_URL}?reference=${reference}`,
      '',
      `location=0,status=0,width=${width},height=${height},left=${left},top=${top}`
    );
  }

  return (
    <>
      <ConfirmationDialog
        open={showRefundModal}
        onCancel={() => setShowRefundModal(false)}
        onConfirm={() => refund()}
        title={t('CONFIRM_REFUND_TITLE')}
        message={t('CONFIRM_REFUND_MESSAGE')}
      />
      <AlertDialog
        open={showErrorDialog}
        title={t('GENERIC_ERROR_TITLE')}
        message={t('GENERIC_ERROR_MESSAGE')}
        onClose={() => setShowErrorDialog(false)} />
      <Dialog
        open={props.open}
        onClose={props.onClose}
        scroll={'paper'}
        fullWidth
        maxWidth='lg'
      >
        <DialogTitle>{t('ORDER_DETAILS_TITLE')}</DialogTitle>
        <DialogContent dividers>
          {loading &&
            <Grid container justifyContent='center'>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          }
          {orderData &&
            <Grid container spacing={2}>
              <Grid container justifyContent='center' item xs={12}>
                <ButtonGroup disabled={loading} size="large" color="primary" variant='contained'>
                  <Button startIcon={<PrintIcon />} onClick={() => { openTicket(orderData.order.reference) }}>{t('PRINT_TICKET')}</Button>
                  <Button startIcon={<FastForwardIcon />} onClick={() => updateStatus()}>{t(getUpdateStatusButtonLabel())}</Button>
                  <Button startIcon={<AccountBalanceIcon />} onClick={() => setShowRefundModal(true)} disabled={orderData.refund !== undefined}>{t('REFUND')}</Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'subtitle2'}>
                  {t('ORDER_DETAILS_HEADER')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <List dense disablePadding>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('ORDER_NUMBER')}
                      secondary={orderData.order.sequence}
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('AMOUNT')}
                      secondary={`${utils.currencyFormat(orderData.order.amount)} ${orderData.venue.currency_iso_code}`}
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('LOCATION')}
                      secondary={orderData.order.location}
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('PAYMENT_METHOD')}
                      secondary={t(orderData.payment.method)}
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('DELIVERY_DATE')}
                      secondary={moment(orderData.order.requested_datetime).format('YYYY-MM-DD HH:mm')}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>
                <List dense disablePadding> 
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('CREATED_AT')}
                      secondary={moment(orderData.order.created).format('YYYY-MM-DD HH:mm')}
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('STATUS')}
                      secondary={<Chip color={getChipColorForOrderStatus(orderData.order.status)} label={t(orderData.order.status)} />}
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('CUSTOMER_NOTES')}
                      secondary={t(orderData.order.customer_notes)}
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('REFUNDED')}
                      secondary={<Chip color={orderData.refund !== undefined ? 'primary' : 'default'} label={orderData.refund !== undefined ? t('YES') : t('NO')} />}
                    />
                  </ListItem>

                </List>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'subtitle2'}>
                  {t('CUSTOMER_DETAILS')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <List dense disablePadding>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('NAME')}
                      secondary={orderData.customer.name}
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('EMAIL')}
                      secondary={orderData.customer.email}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>
                <List dense disablePadding>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('PHONE')}
                      secondary={orderData.customer.phone}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'subtitle2'}>
                  {t('ORDER_LINES')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('PRODUCT')}</TableCell>
                        <TableCell align="right">{t('QUANTITY')}</TableCell>
                        <TableCell align="right">{t('AMOUNT')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderData.order_lines.map(l => (
                        <TableRow key={l.id}>
                          <TableCell>{l.product_name}</TableCell>
                          <TableCell align="right">{l.quantity}</TableCell>
                          <TableCell align="right">{utils.currencyFormat(l.quantity * l.product_price)}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell rowSpan={2} />
                        <TableCell colSpan={1} align="right">{t('TOTAL_AMOUNT')}</TableCell>
                        <TableCell align="right">{utils.currencyFormat(orderData.order.amount)} {orderData.venue.currency_iso_code}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color='default'>
            {t('CLOSE')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )

}