import React from 'react';
import {
  Box,
  Chip,
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../services/utils.service';
import notFoundImg from '../assets/img/not-found.svg';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import DetailsIcon from '@material-ui/icons/Info';
import moment, { Moment } from 'moment';
import { Api } from '../services/api.service';
import { OrderListItem } from '../services/types/orderListItem.type';
import { Vendor } from '../services/types/vendor.type';
import { DatePicker } from '@material-ui/pickers';
import { LoadingButton } from '../components/loadingButton';
import CachedIcon from '@material-ui/icons/Cached';
import { OrderStatus } from '../services/types/orderStatus.type';
import { OrderDetailsModal } from './orderDetailsModal';
import { Config } from '../config';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);

let timeout: any;


const Row = (props: { order: OrderListItem, onOrderSelected: (orderId: number) => void }) => {
  const { order } = props;
  const { t } = useTranslation(['orders']);
  const config = new Config();

  const printTicket = (reference: string) => {
    const width = 600;
    const height = 600;
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    window.open(
      `${config.RECEIPT_URL}?reference=${reference}&print=true`,
      '',
      `location=0,status=0,width=${width},height=${height},left=${left},top=${top}`
    );
  }

  const getChipColorForOrderStatus = (status: OrderStatus) => {
    if (status === OrderStatus.CREATED) {
      return 'default';
    } else if (status === OrderStatus.PROCESSING) {
      return 'secondary';
    }
    return 'primary';
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size='small' onClick={() => props.onOrderSelected(order.order.id)}>
            <DetailsIcon />
          </IconButton>
          {order.order.status === OrderStatus.CREATED &&
            <IconButton size='small' onClick={() => printTicket(order.order.reference)}>
              <PrintIcon />
            </IconButton>
          }
        </TableCell>
        <TableCell>
          {order.order.sequence}
        </TableCell>
        <TableCell>
          {moment(order.order.created).format('YYYY-MM-DD HH:mm')}
        </TableCell>
        <TableCell>
          {order.order.location}
        </TableCell>
        <TableCell>
          {utils.currencyFormat(order.order.amount)}
        </TableCell>
        <TableCell>
          {t(order.payment.method)}
        </TableCell>
        <TableCell>
          {order.customer.name}
        </TableCell>
        <TableCell>
          <Chip color={getChipColorForOrderStatus(order.order.status)} label={t(order.order.status)} />
        </TableCell>
        <TableCell>
          <Chip color={order.refund ? 'primary' : 'default'} label={order.refund ? t('YES') : t('NO')} />
        </TableCell>
        <TableCell>
         {order.order.requested_datetime ? moment(order.order.requested_datetime).format('YYYY-MM-DD HH:mm')  : 'NO APLICA' }
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const getLocationsFromLocalStorage = () => {
  const locationsString = localStorage.getItem('locations');
  if (locationsString) {
    return locationsString.split(',');
  }
  return [];
}
const storeLocationsInLocalStorage = (locations: string[]) => {
  if (locations.length < 1) {
    localStorage.removeItem('locations');
    return;
  }
  localStorage.setItem('locations', locations.join(','));
}

export const Orders = () => {
  const { t } = useTranslation(['orders']);
  const api = new Api();
  const [loading, setLoading] = React.useState(true);
  const [orders, setOrders] = React.useState<OrderListItem[]>([]);

  const [selectedOrderId, selectOrderId] = React.useState<number | null>(null);

  const [refresh, setRefresh] = React.useState('');
  const [vendors, setVendors] = React.useState<Vendor[]>([]);
  const [selectedVendor, selectVendor] = React.useState<any | null>(null);
  const [customerEmail, setCustomerEmail] = React.useState('');
  const [customerName, setCustomerName] = React.useState('');
  const [sequence, setSequence] = React.useState('');
  const [startDate, setStartDate] = React.useState<Moment | null>(null);
  const [requestedDatetime, setRequestedDatetime] = React.useState<Moment | null>(null);
  const [endDate, setEndDate] = React.useState<Moment | null>(null);

  const [next, setNext] = React.useState<number | null>(null);

  const states = ['CREATED', 'PROCESSING', 'DELIVERING'];

  const [selectedStatus, selectStatus] = React.useState<string | null>('CREATED');

  const [selectedLocations, setSelectedLocations] = React.useState<string[]>(getLocationsFromLocalStorage());

  const classes = useStyles();

  const onPrintFinnish = (event: any) => {
    if (event.data.action === 'print_close') {
      // alert(event.data.orderId);
      utils.runAsync(async () => {
        setLoading(true);
        await api.updateOrderStatus(
          event.data.orderId,
          OrderStatus.PROCESSING,
          localStorage.getItem('token')!
        );
      }, (e) => {
        setRefresh(new Date().toISOString());
      });
    }
  }

  React.useEffect(() => {
    window.addEventListener('message', onPrintFinnish, false);
    return () => {
      window.removeEventListener('message', onPrintFinnish);
    }
  }, []);


  const loadData = (reset = false) => {
    utils.runAsync(async () => {
      setLoading(true);
      const response = await api.listOrders(
        localStorage.getItem('token')!,
        {
          vendorId: selectedVendor.id,
          location: selectedLocations.length > 0 ? selectedLocations.join(',') : undefined,
          start: startDate ? startDate.toISOString() : undefined,
          end: endDate ? endDate.toISOString() : undefined,
          status: selectedStatus ? selectedStatus : undefined,
          customerEmail: customerEmail.length > 0 ? customerEmail : undefined,
          customerName: customerName.length > 0 ? customerName : undefined,
          sequence: sequence.length > 0 ? sequence : undefined,
          requestedDatetime: requestedDatetime ? requestedDatetime.toLocaleString() : undefined,
        },
        reset ? undefined : next ? next : undefined
      );
      if (reset) {
        setOrders(response.orders);
      } else {
        setOrders([...orders, ...response.orders]);
      }
      setNext(response.next);
    }, () => {
      setLoading(false);
    })
  }



  React.useEffect(() => {
    utils.runAsync(async () => {
      const adminData = await api.getAdminData(localStorage.getItem('token')!);
      const vendors = await api.getVendors(adminData.venue_id);
      const filteredVendors = vendors.filter(v => adminData.vendor_ids.includes(v.id));
      setVendors(filteredVendors);
      selectVendor(filteredVendors[0]);
    });

  }, []);

  React.useEffect(() => {
    storeLocationsInLocalStorage(selectedLocations);
  }, [selectedLocations]);

  React.useEffect(() => {
    if (selectedVendor === null) return;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      loadData(true);
    }, 100);
  }, [
    selectedVendor,
    selectedLocations,
    selectedStatus,
    startDate,
    endDate,
    customerEmail,
    customerName,
    sequence,
    requestedDatetime,
    refresh
  ]);

  const onOrderSelected = (orderId: number) => {
    selectOrderId(orderId);
  }


  return (
    <>
      <OrderDetailsModal
        open={selectedOrderId !== null}
        orderId={selectedOrderId}
        onClose={() => {
          selectOrderId(null);
          loadData(true);
        }}
      />
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item container spacing={2} alignItems='flex-end'>
            <Grid container item xs={12} justifyContent='flex-end'>
              <LoadingButton loading={loading && orders.length > 0} disabled={loading} startIcon={<CachedIcon />} onClick={() => loadData(true)}>
                {t('REFRESH')}
              </LoadingButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                onChange={(event, value) => {
                  selectVendor(value);
                }}
                value={selectedVendor}
                options={vendors}
                disableClearable={true}
                autoHighlight
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('SEARCH_VENDOR')}
                    variant='standard'
                    fullWidth={true}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )} />
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                onChange={(event, value) => {
                  selectStatus(value);
                }}
                value={selectedStatus}
                options={states}
                autoHighlight
                getOptionLabel={(option) => t(option)}
                getOptionSelected={(option, value) => option === value}
                renderOption={(option) => t(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('SEARCH_STATUS')}
                    variant='standard'
                    fullWidth={true}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )} />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label={t('SEARCH_BY_SEQUENCE')}
                value={sequence}
                onChange={(e) => setSequence(e.target.value)}
                variant='standard'
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Autocomplete
                freeSolo
                multiple
                options={[]}
                value={selectedLocations}
                onChange={(e, value, reason) => {
                  if (Array.isArray(value)) {
                    setSelectedLocations(value as string[]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t('FILTER_BY_LOCATION')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={t('SEARCH_BY_NAME')}
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                variant='standard'
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={t('SEARCH_BY_EMAIL')}
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                variant='standard'
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                clearable
                okLabel={t('OK')}
                cancelLabel={t('CANCEL')}
                clearLabel={t('CLEAR')}
                fullWidth={true}
                label={t('START_DATE')}
                format='DD/MM/YYYY'
                inputVariant='standard'
                value={startDate}
                onChange={(value) => {
                  if (value) {
                    setStartDate(value.startOf('day'));
                  } else {
                    setStartDate(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                clearable
                okLabel={t('OK')}
                cancelLabel={t('CANCEL')}
                clearLabel={t('CLEAR')}
                fullWidth={true}
                label={t('END_DATE')}
                format='DD/MM/YYYY'
                inputVariant='standard'
                value={endDate}
                onChange={(value) => {
                  if (value) {
                    setEndDate(value.startOf('day'));
                  } else {
                    setEndDate(value);
                  }
                }}
              />
            </Grid>

          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>#</TableCell>
                        <TableCell>{t('CREATED_AT')}</TableCell>
                        <TableCell>{t('LOCATION')}</TableCell>
                        <TableCell>{t('AMOUNT')}</TableCell>
                        <TableCell>{t('PAYMENT_METHOD')}</TableCell>
                        <TableCell>{t('CUSTOMER_NAME')}</TableCell>
                        <TableCell>{t('STATUS')}</TableCell>
                        <TableCell>{t('REFUNDED')}</TableCell>
                        <TableCell>{t('DELIVERY_DATE')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders.map(o =>
                        <Row
                          onOrderSelected={onOrderSelected}
                          order={o}
                          key={o.order.id}
                        />)}
                      {loading && orders.length === 0 &&
                        <TableCell colSpan={11}>
                          <Box padding={2}>
                            <Grid container justifyContent='center' item xs={12}>
                              <CircularProgress />
                            </Grid>
                          </Box>
                        </TableCell>
                      }
                      {!loading && orders.length === 0 &&
                        <TableCell colSpan={11}>
                          <Box padding={2}>
                            <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                            <img className={classes.img} src={notFoundImg} />
                          </Box>
                        </TableCell>
                      }
                    </TableBody>

                  </Table>
                  <div style={{ display: 'block' }}>
                    <div style={{ minHeight: 52, display: 'flex', position: 'relative', alignItems: 'center', paddingRight: 10 }}>
                      <div style={{ flex: '1 1 100%' }}></div>
                      <div style={{ flexShrink: 0 }}>
                        <LoadingButton loading={loading && orders.length > 0} disabled={loading || next === null} onClick={() => loadData()}>{t('LOAD_MORE')}</LoadingButton>
                      </div>
                    </div>
                  </div>
                </TableContainer>

              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid >
    </>
  )
}
