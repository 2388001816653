import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route as DomRoute,
  useLocation, Redirect
} from 'react-router-dom';
import { Layout } from './layout/layout';
import { Orders } from './orders/orders';
import { Products } from './products/products';
import { Settings } from './settings/settings';


const PrivateRoute = ({ name, component: Component, ...rest }: { name: string, component: any, exact?: boolean, path: string[] | string }) => {
  return (
    <DomRoute
      {...rest}
      render={(props) => {
        return (<Layout name={name}><Component {...props} /></Layout>)
      }}
    />
  )
}


const ScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

export const Router = () => {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <PrivateRoute exact name={'ORDERS'} path='/orders' component={Orders} />
          <PrivateRoute exact name={'PRODUCTS'} path='/products' component={Products} />
          <PrivateRoute exact name={'SETTINGS'} path='/settings' component={Settings} />
          <Redirect to='/orders' />
        </Switch>
      </BrowserRouter>
    );
  }
}
