import { Button, ButtonGroup, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AlertDialog } from '../components/alertDialog';
import { ConfirmationDialog } from '../components/confirmationDialog';
import { Config } from '../config';
import { Api } from '../services/api.service';
import { DecoratedOrder } from '../services/types/decoratedOrder.type';
import { OrderStatus } from '../services/types/orderStatus.type';
import utils from '../services/utils.service';
import PrintIcon from '@material-ui/icons/Receipt';
import FastForwardIcon from '@material-ui/icons/FastForward';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Vendor } from '../services/types/vendor.type';
import { LoadingButton } from '../components/loadingButton';

type Props = {
    vendor: Vendor | null;
    open: boolean;
    loadVendors: () => void;
    onClose: () => void;
}

export const SettingsModal = (props: Props) => {

    const {t} = useTranslation('settings')
    const api = new Api()
    const config = new Config()

    const [loading, setLoading] = React.useState(false);
    const [enabled, setEnabled] = React.useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const [allowRequestDatetime, setAllowRequestDatetime] = React.useState(null);

    const updateAllowRequestData = () => {
      utils.runAsync(async () => {
          setLoading(true);
          await api.updateVendor(props.vendor?.id, props.vendor?.enabled, enabled, localStorage.getItem('token')!);
          props.loadVendors()
          props.onClose()
        }, (e) => {
          setLoading(false);
          props.loadVendors();
        });
    }

    useEffect(() => {
      const allowRequestDataToBoolean = props.vendor?.allow_request_data === 1
      setEnabled(allowRequestDataToBoolean)
    }, [props.vendor?.allow_request_data])

  return (
    <div>
        <AlertDialog
            open={showErrorDialog}
            title={t('GENERIC_ERROR_TITLE')}
            message={t('GENERIC_ERROR_MESSAGE')}
            onClose={() => setShowErrorDialog(false)} />
        <Dialog 
            open={props.open} 
            onClose={props.onClose}
            scroll={'paper'}
            fullWidth
            maxWidth='md'
        >
        <DialogTitle>{ t('VENDOR_FORM_TITLE') }</DialogTitle>
        <DialogContent dividers>
        <FormControlLabel
            control={
              <Checkbox 
                checked={enabled}
                onChange={() => setEnabled(!enabled)}
              />
            }
            label={t('ENABLE_DATETIME')}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="default">
            {t('CLOSE')}
          </Button>
          <LoadingButton loading={loading} onClick={updateAllowRequestData} color='primary'>
            {t('SAVE')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
  
}
