import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


export const ConfirmationDialog = ({
  open,
  onCancel,
  onConfirm,
  title,
  message
}: {
  open: boolean,
  onCancel: () => void,
  onConfirm: () => void,
  title: string
  message: string
}) => {
  const { t } = useTranslation('layout');
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby='alert-dialog'
    >
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color='secondary' autoFocus>
          {t('CANCEL')}
        </Button>
        <Button onClick={onConfirm} color='primary' autoFocus>
          {t('CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog >
  )
}
