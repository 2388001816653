import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import koImg from '../assets/img/error.svg';
import {
  useTheme,
  useMediaQuery,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Drawer,
  Hidden,
  Container,
  Box,
  CircularProgress,
  Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import logo from '../assets/img/logo-gofun.svg'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@material-ui/icons/Settings';
import utils from '../services/utils.service';
import { Api } from '../services/api.service';
import { AdminData } from '../services/types/adminData.type';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      height: '100%',
      width: '100%',
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    },
    draweSectionTitle: {
      padding: '10px 0 5px 20px'

    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    toolbar: {
      display: 'flex'
    },
    grow: {
      flexGrow: 1
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    drawerPaper: {
      width: drawerWidth
    },
    toolbarSpacer: {
      ...theme.mixins.toolbar,
      display: 'flex',
      justifyContent: 'center'
    },
    logo: {
      width: 80
    },
    expansionDetails: {
      padding: 0
    },
  })
);


const ListItemLink = ({ icon, primary, to, click }: { icon: any, primary: string, to: string, click: () => void }) => {
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<any, any>((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to],
  );
  return (
    <ListItem button component={CustomLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText color='black' primary={primary} />
    </ListItem>
  )
}

export const Layout = ({ children, name }: { children: any, name: string }) => {
  const classes = useStyles();
  const { t } = useTranslation('layout');
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [adminData, setAdminData] = React.useState<AdminData | null>(null);

  const api = new Api();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let token = localStorage.getItem('token');
    if (urlParams.get('token')) {
      token = urlParams.get('token')!;
      localStorage.setItem('token', urlParams.get('token')!);
    }
    if (token) {
      utils.runAsync(async () => {
        setLoading(true);
        setAdminData(await api.getAdminData(token!));
      }, (e) => {
        setLoading(false);
      })
    }
  }, []);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  }



  const drawer = ({ mobile }: { mobile: boolean }) => (
    <>
      <div className={classes.toolbarSpacer}>
        <img className={classes.logo} src={logo} />
      </div>
      <Divider />
      <List>
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/orders'
          primary={t('ORDERS')}
          icon={<ShoppingCartIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/products'
          primary={t('PRODUCTS')}
          icon={<ArtTrackIcon />}
        />
        <ListItemLink
          click={() => mobile && handleDrawerToggle()}
          to='/settings'
          primary={t('SETTINGS')}
          icon={<SettingsIcon />}
        />
      </List>
    </>
  )
  if (loading) {
    return (
      <Grid container justifyContent='center' alignContent='center'>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }
  if (!adminData) {
    return (
      <Grid container justifyContent='center' alignContent='center' spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' align='center'>
            {t('INVALID_LINK_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' align='center'>
            {t('INVALID_LINK_SUBTITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12} container justify='center'>
          <img src={koImg} style={{ maxWidth: 200 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography align='center'>
            {t('INVALID_LINK_EXTENDED_DESCRIPTION')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.main}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color='inherit'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' color='inherit' className={classes.grow}>
            {t(name)}
          </Typography>
          <Typography variant='body2' color='inherit'>
            {adminData.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Drawer
          variant='temporary'
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {drawer({ mobile: true })}
        </Drawer>
        <Hidden xsDown implementation='css'>
          <Drawer
            variant='permanent'
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
          >
            {drawer({ mobile: false })}
          </Drawer>
        </Hidden>
      </nav>
      <Box marginTop={10} width={'100%'}>
        <Container>
          {children}
        </Container>
      </Box>
    </div>
  )
};

