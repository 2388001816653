import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import utils from '../services/utils.service';
import notFoundImg from '../assets/img/not-found.svg';
import { Api } from '../services/api.service';
import { Vendor } from '../services/types/vendor.type';
import { LoadingButton } from '../components/loadingButton';
import EditIcon from '@material-ui/icons/Edit';
import { SettingsModal } from './settingsModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 400,
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    noBottomBorder: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);

let timeout: any;


const VendorRow = (props: { vendor: Vendor, onRefresh: () => void, onVendorSelected: (vendor: Vendor) => void}) => {
  const { vendor, onRefresh } = props;
  const { t } = useTranslation(['settings']);

  const [loading, setLoading] = React.useState(false);

  const api = new Api();

  const toggle = () => {
  utils.runAsync(async () => {
      setLoading(true);
      const allowRequestDataToBoolean = vendor.allow_request_data === 1 
      await api.updateVendor(vendor.id, !vendor.enabled, allowRequestDataToBoolean, localStorage.getItem('token')!);
    }, (e) => {
      setLoading(false);
      onRefresh();
    });

  }


  return (
    <React.Fragment>
      <TableRow>
      <TableCell>
      <IconButton size='small' onClick={() => props.onVendorSelected(vendor)}>
            <EditIcon />
          </IconButton>
          </TableCell>
        <TableCell>
          {vendor.name}
        </TableCell>
        <TableCell>
          {vendor.enabled &&
            <Button
              disabled={loading}
              onClick={toggle}
              color='secondary'
              variant='contained'>
              {t('DISABLE')}
            </Button>
          }
          {!vendor.enabled &&
            <Button
              disabled={loading}
              onClick={toggle}
              color='primary'
              variant='contained'>
              {t('ENABLE')}
            </Button>
          }

        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export const Settings = () => {
  const { t } = useTranslation(['settings']);
  const api = new Api();

  const [loading, setLoading] = React.useState(true);
  const [vendors, setVendors] = React.useState<Vendor[]>([]);
  const [editVendorRow, setEditVendorRow] = React.useState(false);
  const [selectedVendor, setSelectedVendor] = React.useState<Vendor | null>(null);

  const classes = useStyles();

  const loadVendors = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const adminData = await api.getAdminData(localStorage.getItem('token')!);
      const vendors = await api.getVendors(adminData.venue_id);
      const filteredVendors = vendors.filter(v => adminData.vendor_ids.includes(v.id));
      setVendors(filteredVendors);
    });
  }


  React.useEffect(() => {
    loadVendors();
  }, []);

  const onVendorSelected = (vendor: Vendor) => {
    setSelectedVendor(vendor);
  }

  return (
    <>
    <SettingsModal 
       open={selectedVendor !== null || editVendorRow}
       vendor={selectedVendor}
       loadVendors={loadVendors}
       onClose={() => {
        setSelectedVendor(null);
        loadVendors()
       }}
    />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                      <TableCell></TableCell>
                        <TableCell>{t('VENDOR')}</TableCell>
                        <TableCell>{t('ACTIONS')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vendors.map(v =>
                        <VendorRow
                          vendor={v}
                          onRefresh={loadVendors}
                          onVendorSelected={onVendorSelected}
                          key={v.id}
                        />)}
                      {loading && vendors.length === 0 &&
                        <TableCell colSpan={2}>
                          <Box padding={2}>
                            <Grid container justifyContent='center' item xs={12}>
                              <CircularProgress />
                            </Grid>
                          </Box>
                        </TableCell>
                      }
                      {!loading && vendors.length === 0 &&
                        <TableCell colSpan={2}>
                          <Box padding={2}>
                            <Typography align='center' variant='h5'>{t('NO_DATA')}</Typography>
                            <img className={classes.img} src={notFoundImg} />
                          </Box>
                        </TableCell>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid >
    </>
  )
}
