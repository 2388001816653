import * as voucherCode from 'voucher-code-generator';
const utils = {
  roundToDecimals(input: number, decimals: number = 2): number {
    return Number(
      Math.round(Number(input + 'e' + decimals))
      + 'e-' + decimals,
    );
  },
  round: (aNumber: number) => Math.round((aNumber + Number.EPSILON) * 100) / 100,
  runAsync: (runnable: () => Promise<void>, onDone?: (error?: any) => void) => {
    runnable().then(() => {
      if (onDone) {
        onDone();
      }
    }).catch(e => {
      if (e) {
        console.error(e);
      }
      if (onDone) {
        onDone(e);
      }
    });
  },
  currencyFormat: (aNumber: number | undefined) => new Intl.NumberFormat(
    ['es-ES', 'en-GB', 'en-US'],
    { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }
  ).format(aNumber ? aNumber : 0),

  getStringOrDefault(v: string | number | undefined | null, def: string = ''): string {
    if (typeof v === 'number') {
      return v.toString(10);
    }
    if (v) return v;
    return def;
  },
}
export default utils
