import { FilterSharp } from '@material-ui/icons';
import { Config } from '../config';
import { AdminData } from './types/adminData.type';
import { DecoratedOrder } from './types/decoratedOrder.type';
import { OrderListItem } from './types/orderListItem.type';
import { OrderStatus } from './types/orderStatus.type';
import { Product } from './types/product.type';
import { Vendor } from './types/vendor.type';
import { Orders } from '../orders/orders';

export class Api {

  readonly config: Config;

  constructor() {
    this.config = new Config();
  }

  async getAdminData(token: string): Promise<AdminData> {
    const response = await fetch(
      this.config.API_URL + '/admin/me',
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    if (!response.ok) {
      throw new Error('Got wrong response while fetch admin data.');
    }
    return response.json();
  }

  async updateOrderStatus(orderId: number, status: OrderStatus, token: string) {
    const response = await fetch(
      this.config.API_URL + '/orders/' + orderId,
      {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ status })
      }
    );
    if (!response.ok) {
      throw new Error('Got wrong response while updating order.');
    }
  }

  async upsertProduct(product: Product, vendorId:number, token: string) {
    const response = await fetch(
      this.config.API_URL + '/vendors/'+vendorId+'/products',
      {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...product,
          id:product.id?product.id:undefined
        })
      }
    );
    if (!response.ok) {
      throw new Error('Got wrong response while upserting product.');
    }
  }

  async listProducts(vendorId: number): Promise<Product[]> {
    const response = await fetch(
      this.config.API_URL + '/vendors/' + vendorId + '/products',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (!response.ok) {
      throw new Error('Got wrong response while listing products for vendor');
    }
    return (await response.json()).products;
  }

  async refundPayment(paymentId: number, token: string) {
    const response = await fetch(
      this.config.API_URL + '/payments/' + paymentId + '/refund',
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );
    if (!response.ok) {
      throw new Error('Got wrong response while refunding payment.');
    }
  }

  async getVendors(venueId: number): Promise<Vendor[]> {
    const response = await fetch(
      this.config.API_URL + '/venues/' + venueId + '/vendors',
      { method: 'GET' }
    );
    if (!response) {
      throw new Error('Got wrong response while fetching vendors.');
    }
    return (await response.json()).vendors;
  }

  async getOrder(token: string, orderId: number): Promise<DecoratedOrder> {
    const response = await fetch(
      this.config.API_URL + '/orders/' + orderId.toString(10),
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not get order');
    }
    return response.json();
  }

  async listOrders(
    token: string,
    filters: {
      start?: string;
      end?: string;
      location?: string;
      vendorId: number;
      status?: string;
      customerEmail?: string;
      customerPhone?: string;
      customerName?: string;
      sequence?: string;
      requestedDatetime?: string;
    },
    next?: number,
  ): Promise<{ orders: OrderListItem[], next: number | null }> {
    const queryParams = new URLSearchParams();
    queryParams.append('vendor_id', filters.vendorId.toString(10));
    if (next) {
      queryParams.append('next', next.toString(10));
    }
    if (filters.sequence) {
      queryParams.append('sequence', filters.sequence);
    }
    if (filters.location) {
      queryParams.append('location', filters.location);
    }
    if (filters.start) {
      queryParams.append('start', filters.start);
    }
    if (filters.end) {
      queryParams.append('end', filters.end);
    }
    if (filters.status) {
      queryParams.append('status', filters.status);
    }
    if (filters.customerName) {
      queryParams.append('customer_name', filters.customerName);
    }
    if (filters.customerEmail) {
      queryParams.append('customer_email', filters.customerEmail);
    }
    if (filters.customerPhone) {
      queryParams.append('customer_phone', filters.customerPhone);
    }
    const response = await fetch(
      this.config.API_URL + '/orders?' + queryParams.toString(),
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not get orders');
    }
    const jsonResponse = await response.json();
    
    return { orders: jsonResponse.orders, next: jsonResponse.meta.next ? jsonResponse.meta.next : null }
  }

  async updateVendor(id: number, enabled: boolean, allowRequestData: boolean, token: string) {
    const response = await fetch(
      this.config.API_URL + '/vendors/' + id,
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ enabled, allowRequestData })
      }
    );
    if (!response.ok) {
      throw new Error('Got wrong response while updating vendor.');
    }
  }

}