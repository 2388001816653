import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { AlertDialog } from '../components/alertDialog';
import { Config } from '../config';
import { Api } from '../services/api.service';
import { Product } from '../services/types/product.type';
import { LoadingButton } from '../components/loadingButton';
import utils from '../services/utils.service';
import { validate } from '@material-ui/pickers';
import { Vendor } from '../services/types/vendor.type';
import { Autocomplete } from '@material-ui/lab';

type Props = {
  product: Product | null;
  vendor: Vendor;
  open: boolean;
  onClose: () => void;
}
export const ProductModal = (props: Props) => {

  const { t } = useTranslation('products');

  const api = new Api();
  const config = new Config();

  const [loading, setLoading] = React.useState(false);
  const [showErrorDialog, setShowErrorDialog] = React.useState(false);

  const [errors, setErrors] = React.useState<any>({});
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [tags, setTags] = React.useState('');
  const [tax, setTax] = React.useState('');
  const [image, setImage] = React.useState('');
  const [enabled, setEnabled] = React.useState(true);
  const [price, setPrice] = React.useState('');

  const [vendor, setVendor] = React.useState('');

  const validate = () => {
    const validationErrors: any = {};
    if (name.length === 0) {
      validationErrors.name = true;
    }
    if (!parseFloat(tax)) {
      validationErrors.tax = true;
    }
    if (!parseFloat(price)) {
      validationErrors.price = true;
    }
    if (image.length === 0) {
      validationErrors.image = true;
    }
    setErrors(validationErrors);
    return Object.values(validationErrors).find(v => v === true) === undefined;
  }

  const resetErrors = () => {
    setErrors({});
  }

  const saveProduct = () => {
    if (!validate()) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.upsertProduct(
        {
          id: props.product ? props.product.id : 0,
          name,
          description,
          tags: tags.split(','),
          cover_image: image,
          price: parseFloat(price),
          tax: parseFloat(tax),
          enabled
        },
        props.vendor.id,
        localStorage.getItem('token')!
      )
    }, (e) => {
      setLoading(false);
      if (e) {
        setShowErrorDialog(true);
      }else{
        props.onClose();
      }
    });
  }

  React.useEffect(() => {
    if (props.product) {
      resetErrors();
      setName(props.product.name);
      setDescription(props.product.description);
      setImage(props.product.cover_image);
      setTags(props.product.tags.join(', '));
      setPrice(props.product.price.toString(10));
      setTax(props.product.tax.toString(10));
      setEnabled(props.product.enabled)
    } else {
      resetErrors();
      setName('');
      setDescription('');
      setTags('');
      setTax('');
      setImage('');
      setEnabled(true);
      setPrice('');
    }
    if (props.vendor) {
      setVendor(props.vendor.name);
    }
  }, [props.product,props.vendor]);

  return (
    <>
      <AlertDialog
        open={showErrorDialog}
        title={t('GENERIC_ERROR_TITLE')}
        message={t('GENERIC_ERROR_MESSAGE')}
        onClose={() => setShowErrorDialog(false)} />
      <Dialog
        open={props.open}
        onClose={props.onClose}
        scroll={'paper'}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>{t('PRODUCT_FORM_TITLE')}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('VENDOR')}
                value={vendor}
                disabled
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('NAME')}
                error={errors.name}
                value={name}
                helperText={errors.name ? t('REQUIRED') : ''}
                onChange={(e) => {
                  setName(e.target.value);
                  resetErrors();
                }}
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('DESCRIPTION')}
                error={errors.description}
                value={description}
                helperText={errors.description ? t('REQUIRED') : ''}
                onChange={(e) => {
                  setDescription(e.target.value)
                  resetErrors();
                }}
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('TAGS')}
                error={errors.tags}
                value={tags}
                helperText={t('TAGS_HELP_TEXT')}
                onChange={(e) => {
                  setTags(e.target.value)
                  resetErrors();
                }}
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('IMAGE')}
                error={errors.image}
                value={image}
                helperText={t('IMAGE_HELP_TEXT')}
                onChange={(e) => {
                  setImage(e.target.value)
                  resetErrors();
                }}
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('PRICE')}
                error={errors.price}
                value={price}
                helperText={errors.price ? t('AMOUNT_ERROR') : ''}
                onChange={(e) => {
                  resetErrors();
                  setPrice(e.target.value)
                }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>{'€UR'}</InputAdornment>
                }}
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('TAX')}
                error={errors.tax}
                value={tax}
                helperText={errors.tax ? t('AMOUNT_ERROR') : ''}
                onChange={(e) => {
                  resetErrors();
                  setTax(e.target.value)
                }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>{'%'}</InputAdornment>
                }}
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={enabled} onChange={() => setEnabled(!enabled)} />}
                label={t('ENABLED')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color='default'>
            {t('CLOSE')}
          </Button>
          <LoadingButton loading={loading} onClick={() => saveProduct()} color='primary'>
            {t('SAVE')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )

}